@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@1;300;400;500;700&display=swap);
/* Importing new default font "Karla" */

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  /* hide scrollbar */
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

/* hide scrollbar */
body::-webkit-scrollbar {
  /* WebKit */
  display: none;
}

main::-webkit-scrollbar {
  /* WebKit */
  display: none;
}

.react-reveal::-webkit-scrollbar {
  /* WebKit */
  display: none;
}

html,
body,
#root,
.App {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;
  min-width: 100%;
}

body {
  overflow: hidden;
  background: #fff;
  color: #333;
  -webkit-font-smoothing: antialised;

}





.App {
  overflow: inherit;
}

.activated {
  font-weight: 600 !important;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

/* CSS for responsive iframe */
/* ========================= */

/* outer wrapper: set max-width & max-height; max-height greater than padding-bottom % will be ineffective and height will = padding-bottom % of max-width */
#Iframe-Master-CC-and-Rs {
  max-width: 100%;
  max-height: 95%;
  height: 90%;
  overflow: hidden;
  position: relative;
  margin: -25px;
}

/* inner wrapper: make responsive */
.responsive-wrapper {
  position: relative;
  height: 90%;
  /* gets height from padding-bottom */

  /* put following styles (necessary for overflow and scrolling handling on mobile devices) inline in .responsive-wrapper around iframe because not stable in CSS:
      -webkit-overflow-scrolling: touch; overflow: auto; */

}

.responsive-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  margin: 0;
  padding: 0;
  border: none;
}

/* padding-bottom = h/w as % -- sets aspect ratio */
/* YouTube video aspect ratio */
.responsive-wrapper-wxh-572x612 {
  height: 100%;
  padding-bottom: 5%;
}

/* general styles */
/* ============== */
.set-border {
  border: 5px inset #4f4f4f;
}

.set-box-shadow {
  box-shadow: 4px 4px 14px #4f4f4f;
}

.set-padding {
  padding: 15px;
}

.set-margin {
  margin: 10px;
}

.center-block-horiz {
  margin-left: auto !important;
  margin-right: auto !important;
}

a:-webkit-any-link {
  color: transparent;
  cursor: pointer;
  text-decoration: none;
}

a:any-link {
  color: transparent;
  cursor: pointer;
  text-decoration: none;
}

p a:-webkit-any-link {

  color: inherit;
  cursor: pointer;
  text-decoration: none;

}

p a:any-link {

  color: inherit;
  cursor: pointer;
  text-decoration: none;

}

a:-webkit-any-link {
  color: transparent;
  cursor: pointer;
  text-decoration: none;
}

p a:-webkit-any-link {

  color: inherit;
  cursor: pointer;
  text-decoration: none;

}

/* format textarea as Material Design */
textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  border-radius: 5px;
  resize: none;
}

/* remove asterisk from all input fields */
.MuiFormLabel-asterisk {
  display: none;
}

/* set outline color of inputs as color logo brand */
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #30B6BC !important;
  border-width: 2px;
}

/* Small Devices, Tablets */
@media (min-width : 601px) and (max-width : 800px) {


  .mob {
    height: 100% !important;
    width: 50vw !important;
    margin: auto !important;
  }

}

.react-reveal {
  overflow: auto;
  min-width: 100%;
}

@-webkit-keyframes onAutoFillStart {
  from {
    /**/
  }

  to {
    /**/
  }
}

@keyframes onAutoFillStart {
  from {
    /**/
  }

  to {
    /**/
  }
}

@-webkit-keyframes onAutoFillCancel {
  from {
    /**/
  }

  to {
    /**/
  }
}

@keyframes onAutoFillCancel {
  from {
    /**/
  }

  to {
    /**/
  }
}

.MuiInputBase-input:-webkit-autofill {
  /* Expose a hook for JavaScript when autofill is shown
    JavaScript can capture 'animationstart' events */
  -webkit-animation-name: onAutoFillStart !important;
          animation-name: onAutoFillStart !important;

  /* Make the background color become yellow really slowly */
  /* transition: background-color 50000s ease-in-out 0s; */
}

.MuiInputBase-input:not(:-webkit-autofill) {
  /* Expose a hook for JS onAutoFillCancel
    JavaScript can capture 'animationstart' events */
  -webkit-animation-name: onAutoFillCancel;
          animation-name: onAutoFillCancel;
}

/* input[name=name] {
  text-transform:capitalize;
} */

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.reasearchDataGrid {
  color: #576B77 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.reasearchDataGridSmall {
  color: #576B77 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  border: none;
  border-right: 1px solid #e0e0e0;
}

.MuiDataGrid-root .MuiDataGrid-colCellCheckbox {
  border-right: 1px solid #e0e0e0;
  min-height: 56px;
}

.MuiDataGrid-withBorder {
  border: none;
}

.MuiDataGrid-root .MuiDataGrid-window {
  overflow-y: auto !important;
}

.MuiDataGrid-root .MuiDataGrid-window::-webkit-scrollbar {
  width: 0.7em;
  height: 0.7em;
}

.MuiDataGrid-root .MuiDataGrid-window::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.MuiDataGrid-root .MuiDataGrid-window::-webkit-scrollbar-thumb {
  background-color: #a3a4a5;
  border-radius: 8px;
}

td.MuiTableCell-root {
  color: #576B77;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabsNewReasearch {
  margin-bottom: 24px;
}

.tabsNewReasearch .MuiTab-textColorInherit.Mui-disabled {
  opacity: 1;
}

.MuiTablePagination-menuItem {
  color: #576B77 !important
}

.relatorioContent {
  max-width: 90%;
  width: 90%;
  margin-top: 1em;
  margin-bottom: 2em;
}

.divPaperContent {
  max-width: 700px;
  width: 700px;
  margin-top: 1em;
  margin-bottom: 2em;
}

.borderAppBar {
  border: 1px solid #DEDEDE;
  border-bottom: 0;
  border-radius: 10px 10px 0 0;
}

.row-actions {
  font-size: 12px;
  color: #576B77;
  font-weight: 500;
}

.cardForm {
  border: 1px solid #DEDEDE;
  border-top: 0;
  background-color: #fff;
  border-radius: 10px;
}

.cardFormCustomRadius {
  max-width: 700px;
  width: 700px;
  border: 1px solid #DEDEDE;
  border-top: 0;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
}

.textAreaSize {
  min-width: 100%;
  padding: 6px;
  border-color: #A3A4A5;
  color: #576B77;
  font-size: 14px;
}

.paddingTabs {
  padding: 0 32px;
}

.paddingTabsCustom {
  padding: 0 32px 32px 32px;
}

.footerButtonsTabs {
  background-color: #f0f0f0;
}

.buttonModal {
  margin: 8px 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: none;
}

.buttonCancel {
  box-shadow: none;
  font-weight: 600 !important;
  background-color: #fff !important;
}

.buttonCancel:hover {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.d-none {
  display: none !important;
}

.w-100 {
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield !important;
  /* Firefox */
}

span.Mui-disabled {
  opacity: 0.25;
}
